<template>
  <b-card no-body>
    <cp-card-head
      without-edit
      :card-name="$t('configurationJurisdictionsPerToken.perCountryTable.title')"
      @onTokenSpecificSelected="handleTokenSpecificSelected"
    />

    <cp-table
      ref="cpTable"
      get-data-action="jurisdictionsPerToken/getJurisdictionsPerTokenCountries"
      :url-params="urlParams"
      :fields="fields"
      :filters="filterByTokenSpecific"
      default-sort-direction="asc"
    >
      <template
        slot="countryName"
        slot-scope="{ rowData }"
      >
        <cp-country-name
          :country-code="rowData.item.countryCode"
          :locale="locale"
        />
      </template>
      <template
        slot="qualificationType"
        slot-scope="{ rowData }"
      >
        {{ getQualificationType(rowData.item) }}
      </template>
      <template
        slot="tokenSpecific"
        slot-scope="{ rowData }"
      >
        <span
          class="ion"
          :class="{
            'ion-md-checkmark text-primary': rowData.item.isTokenSpecific,
            'ion-md-close text-light': !rowData.item.isTokenSpecific
          }"
        />
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex justify-content-start">
          <b-btn
            v-b-tooltip.hover="true"
            class="mr-3"
            variant="default edit-operator-btn btn-xs md-btn-flat"
            :title="$t('common.button.edit')"
            @click="editElem(rowData.item)"
          >
            <i class="ion ion-md-create" />
            {{ $t('common.button.edit') }}
          </b-btn>
          <b-btn
            v-if="rowData.item.isTokenSpecific"
            v-b-tooltip.hover="true"
            class="mr-3"
            variant="default delete-operator-btn btn-xs md-btn-flat"
            :title="$t('common.button.reset')"
            @click="resetElem(rowData.item)"
          >
            <i class="ion ion-md-create" />
            {{ $t('common.button.reset') }}
          </b-btn>
        </div>
      </template>
    </cp-table>

    <cp-jurisdictions-edit-modal
      ref="CpJurisdictionsEditModal"
      :modal-data="modalData"
      @onSubmit="saveChanges"
    />
    <cp-confirm-modal
      ref="cpConfirmModal"
      :title="$t('configurationJurisdictionsPerToken.confirmDeleteModal.title')"
      @onOk="resetJurisdictionPerToken"
    >
      {{ $t('configurationJurisdictionsPerToken.confirmDeleteModal.message') }}
    </cp-confirm-modal>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpCardHead from './card-head';
import CpCountryName from '~/components/common/country-name';
import CpTable from '~/components/shared/cp-table';
import { getCountryName } from '~/utilities/i18n-util';
import CpJurisdictionsEditModal from './jurisdictions-per-token-edit-modal';
import fields from '../fields';
import { qualificationTypeLabels } from '../options';

export default {
  name: 'JurisdictionsPerTokenTable',

  components: {
    CpConfirmModal,
    CpCountryName,
    CpTable,
    CpCardHead,
    CpJurisdictionsEditModal,
  },

  data() {
    return {
      fields,
      modalData: {
        qualification: {},
      },
      urlParams: {
        issuerId: this.$route.params.idIssuer,
        tokenId: this.$route.params.tokenId,
      },
      locale: null,
      dataForDelete: {},
      filterByTokenSpecific: {},
    };
  },

  watch: {
    $route: {
      handler() {
        this.urlParams = {
          issuerId: this.$route.params.idIssuer,
          tokenId: this.$route.params.tokenId,
        };
      },
    },
  },

  created() {
    this.$store.watch(({ global }) => {
      if (global && global.locale !== this.locale) {
        this.locale = global.locale;
      }
    });
  },
  methods: {
    ...mapActions('jurisdictionsPerToken', [
      'fetchTokenCountryByCode',
      'updateTokenCountrySetting',
      'updateTokenStateRegionSetting',
      'fetchTokenStateRegionSetting',
      'resetTokenCountrySetting',
      'resetTokenStateRegionSetting',
    ]),
    ...mapActions('jurisdictions', [
      'fetchCountryByCode',
      'fetchIssuerStateRegionSetting',
    ]),

    getStateRegionName(stateAndRegionId) {
      if (!stateAndRegionId) return null;

      return this.statesAndRegions.find(item => item.id === stateAndRegionId).name;
    },

    editElem({ countryCode, stateAndRegionCode, areaName, areaType, isTokenSpecific }) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      let promise = stateAndRegionCode
              ? this.fetchIssuerStateRegionSetting({ issuerId, countryCode, stateAndRegionCode, areaType })
              : this.fetchCountryByCode({ issuerId, countryCode });

      if (isTokenSpecific) {
       promise = stateAndRegionCode
                ? this.fetchTokenStateRegionSetting({ issuerId, tokenId, countryCode, stateAndRegionCode, areaType })
                : this.fetchTokenCountryByCode({ issuerId, tokenId, countryCode });
      }

      promise.then((data) => {
        this.modalData = {
          ...data,
          areaType,
          areaName,
          stateAndRegionCode,
          countryCode,
          countryName: getCountryName(countryCode, this.locale),
        };
        this.$refs.CpJurisdictionsEditModal.show();
      });
    },

    resetElem({ countryCode, stateAndRegionCode, areaType }) {
      this.dataForReset = { countryCode, stateAndRegionCode, areaType };
      this.$refs.cpConfirmModal.$refs.confirmModal.show();
    },

    resetJurisdictionPerToken() {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      const { countryCode, stateAndRegionCode, areaType } = this.dataForReset;

      const promise = stateAndRegionCode
              ? this.resetTokenStateRegionSetting({ issuerId, tokenId, countryCode, stateAndRegionCode, areaType })
              : this.resetTokenCountrySetting({ issuerId, tokenId, countryCode });

      promise.then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.cpConfirmModal.$refs.confirmModal.hide();
      });
    },

    saveChanges(data) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      const { countryCode, areaType, stateAndRegionCode } = data;

      const promise = stateAndRegionCode
        ? this.updateTokenStateRegionSetting({ issuerId, tokenId, countryCode, stateAndRegionCode, data, areaType })
        : this.updateTokenCountrySetting({ issuerId, tokenId, countryCode, data });

      promise.then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.CpJurisdictionsEditModal.$refs.jurisdictionsModal.$refs.generalModal.hide();
      });
    },

    getQualificationType({ qualificationType, qualificationName }) {
      if (qualificationType === 'specific') return qualificationName;
      return this.$t(qualificationTypeLabels[qualificationType]);
    },

    handleTokenSpecificSelected(value) {
      this.filterByTokenSpecific = { isTokenSpecific: value };
    },
  },
};
</script>

<style lang="scss" scoped>
  .card-header {
    border-bottom: none;
  }
</style>
