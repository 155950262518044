var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('cp-card-head',{attrs:{"without-edit":"","card-name":_vm.$t('configurationJurisdictionsPerToken.perCountryTable.title')},on:{"onTokenSpecificSelected":_vm.handleTokenSpecificSelected}}),_c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"jurisdictionsPerToken/getJurisdictionsPerTokenCountries","url-params":_vm.urlParams,"fields":_vm.fields,"filters":_vm.filterByTokenSpecific,"default-sort-direction":"asc"},scopedSlots:_vm._u([{key:"countryName",fn:function(ref){
var rowData = ref.rowData;
return [_c('cp-country-name',{attrs:{"country-code":rowData.item.countryCode,"locale":_vm.locale}})]}},{key:"qualificationType",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.getQualificationType(rowData.item))+" ")]}},{key:"tokenSpecific",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"ion",class:{
          'ion-md-checkmark text-primary': rowData.item.isTokenSpecific,
          'ion-md-close text-light': !rowData.item.isTokenSpecific
        }})]}},{key:"actions",fn:function(ref){
        var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticClass:"mr-3",attrs:{"variant":"default edit-operator-btn btn-xs md-btn-flat","title":_vm.$t('common.button.edit')},on:{"click":function($event){return _vm.editElem(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create"}),_vm._v(" "+_vm._s(_vm.$t('common.button.edit'))+" ")]),(rowData.item.isTokenSpecific)?_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticClass:"mr-3",attrs:{"variant":"default delete-operator-btn btn-xs md-btn-flat","title":_vm.$t('common.button.reset')},on:{"click":function($event){return _vm.resetElem(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create"}),_vm._v(" "+_vm._s(_vm.$t('common.button.reset'))+" ")]):_vm._e()],1)]}}])}),_c('cp-jurisdictions-edit-modal',{ref:"CpJurisdictionsEditModal",attrs:{"modal-data":_vm.modalData},on:{"onSubmit":_vm.saveChanges}}),_c('cp-confirm-modal',{ref:"cpConfirmModal",attrs:{"title":_vm.$t('configurationJurisdictionsPerToken.confirmDeleteModal.title')},on:{"onOk":_vm.resetJurisdictionPerToken}},[_vm._v(" "+_vm._s(_vm.$t('configurationJurisdictionsPerToken.confirmDeleteModal.message'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }