export const qualificationTypeLabels = {
  standard: 'configurationJurisdictionsPerToken.perCountryTable.status.qualTypes.standard',
  specific: 'configurationJurisdictionsPerToken.perCountryTable.status.qualTypes.specific',
};

export const assetSpecificOptions = [
  { i18nKey: 'common.label.all', value: null },
  { i18nKey: 'configurationJurisdictionsPerToken.perCountryTable.assetSpecificOptions.yes', value: true },
  { i18nKey: 'configurationJurisdictionsPerToken.perCountryTable.assetSpecificOptions.no', value: false },
];
