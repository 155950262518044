const tdClass = 'align-middle';

export default [
  {
    key: 'countryName',
    i18nKey: 'configurationJurisdictionsPerToken.perCountryTable.label.table.country',
    sortable: true,
    tdClass,
  },
  {
    key: 'stateAndRegionName',
    i18nKey: 'configurationJurisdictionsPerToken.perCountryTable.label.table.regionState',
    sortable: true,
    tdClass,
  },
  {
    key: 'qualificationType',
    i18nKey: 'configurationJurisdictionsPerToken.perCountryTable.label.table.qualificationType',
    tdClass,
  },
  {
    key: 'tokenSpecific',
    i18nKey: 'configurationJurisdictionsPerToken.perCountryTable.label.table.tokenSpecific',
    tdClass,
  },
  {
    key: 'actions',
    label: ' ',
    tdClass: `text-nowrap ${tdClass} text-center`,
  },
];
