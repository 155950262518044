<template>
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col-md-10">
        <h4 class="mb-0 text-primary">
          {{ cardName }}
        </h4>
      </div>
      <div class="col-md-2">
        <cp-select
          name="jurisdictionToken"
          :label="$t('configurationJurisdictionsPerToken.perCountryTable.label.filterByTokenSpecific')"
          :options="assetSpecificOptions"
          @input="handleTokenSelected"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { CpSelect } from '@/components/common/standalone-components/inputs';
  import { assetSpecificOptions } from '../options';

export default {
  name: 'CardHead',
  components: {
    CpSelect,
  },
  props: {
    cardName: {
      type: String,
      default: '',
    },
    tokenOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
return {
    assetSpecificOptions,
  };
},
  methods: {
    handleTokenSelected(value, previousValue) {
      this.$emit('onTokenSpecificSelected', value, previousValue);
    },
  },
};
</script>
